<template>   
    <v-dialog v-model="dialog" :fullscreen="isMobile" scrollable  persistent width="800px"
              >
        
        <v-card tile class="filter-card" max-height="750px">

            <v-card-title class="secondary white--text">
                <span class="text-h5">Apply Search Filters</span>
            </v-card-title>

            <v-card-text class="pa-0">
                <v-container>

                    <v-form v-model="filter_form_valid" ref="filter_form">

                        <div class="ma-5 filter-text">

                            <!--ID Search-->
                            <div class="text-h6">RPD User ID</div>
                            <v-divider class="mb-2"></v-divider>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="filter_form.user_id" type="number" label="ID"> </v-text-field>
                                </v-col>
                            </v-row>

                            <!--Submitted date-->
                            <div class="text-h6">Registration Submitted</div>
                            <v-divider class="mb-2"></v-divider>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="filter_form.submitted_sdate" type="date" label="Start Date" :rules="[field_rules.submitted_date_rules]"> </v-text-field>

                                </v-col>
                                <v-col>
                                    <v-text-field v-model="filter_form.submitted_edate" type="date" label="End Date" :rules="[field_rules.submitted_date_rules]"> </v-text-field>
                                </v-col>
                            </v-row>


                            <!--Approved date-->
                            <div class="text-h6">Registration Approved</div>
                            <v-divider class="mb-2"></v-divider>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="filter_form.approved_sdate" type="date" label="Start Date" :rules="[field_rules.approved_date_rules]"> </v-text-field>

                                </v-col>
                                <v-col>
                                    <v-text-field v-model="filter_form.approved_edate" type="date" label="End Date" :rules="[field_rules.approved_date_rules]"> </v-text-field>
                                </v-col>
                            </v-row>

                            <!--Last Logged in-->
                            <div class="text-h6">Last Logged In</div>
                            <v-divider class="mb-2"></v-divider>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="filter_form.last_login_sdate" type="date" label="Start Date" :rules="[field_rules.last_login_rules]"> </v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="filter_form.last_login_edate" type="date" label="End Date" :rules="[field_rules.last_login_rules]"> </v-text-field>
                                </v-col>
                            </v-row>




                        </div>

                    </v-form>

                </v-container>
               
            </v-card-text>

          



            <v-card-actions style=" align-self: center;">
                <v-btn color="blue" @click="resetFilters" text class="pa-2 ma-2 text-center">Reset</v-btn>
                <v-btn color="red" @click="closeDialog" text class="pa-2 ma-2 text-center">Cancel</v-btn>
                <v-btn color="primary" @click="applyFilters" :disabled="!filter_form_valid" outlined class="pa-2 ma-2 text-center">Apply</v-btn>
            </v-card-actions>

        </v-card>

    </v-dialog>

  


</template>

<style lang="scss" >
    .filter-dialog {
    }

    .filter-card {
        height: 83vh;
    }

    .filter-text {
        height: 52vh;
    }

    @media only screen and (max-width: 1200px) {

        .filter-text {
            height: 80vh;
        }
    }

    @media only screen and (max-width: 600px) {

        .filter-card {
            height: 83vh;
        }

        .filter-text {
            height: 80vh;
        }
    }
</style>

<script>
    /* eslint-disable */
    export default {
        name: "filterdialog",
        props: ["dialog", "filter_form"],
        data() {
            return {
                isMobile: this.$vuetify.breakpoint.mobile,
                filter_form_valid: false,
                filters_active_text: [],
                field_rules: {
                    last_login_rules: value => {
                        var bool = false;
                        if (this.filter_form.last_login_sdate.length == 0 && this.filter_form.last_login_edate.length == 0) {
                            bool = true;
                        }
                        else if (this.filter_form.last_login_sdate.length > 0 && this.filter_form.last_login_edate.length > 0) {
                            bool = true;
                        }

                        else {
                            bool = false;
                        }
                        return bool || 'The must enter a valid date range using both fields.'
                    },
                    approved_date_rules: value => {
                        var bool = false;
                        if (this.filter_form.approved_sdate.length == 0 && this.filter_form.approved_edate.length == 0) {
                            bool = true;
                        }
                        else if (this.filter_form.approved_sdate.length > 0 && this.filter_form.approved_edate.length > 0) {
                            bool = true;
                        }

                        else {
                            bool = false;
                        }
                        return bool || 'The must enter a valid date range using both fields.'
                    },
                    submitted_date_rules: value => {
                        var bool = false;
                        if (this.filter_form.submitted_sdate.length == 0 && this.filter_form.submitted_edate.length == 0) {
                            bool = true;
                        }
                        else if (this.filter_form.submitted_sdate.length > 0 && this.filter_form.submitted_edate.length > 0) {
                            bool = true;
                        }

                        else {
                            bool = false;
                        }
                        return bool || 'The must enter a valid date range using both fields.'
                    },

                },
            };
        },
        methods: {
            closeDialog() {
                this.$emit("closeDialog");
            },
            applyFilters() {
                this.$emit("applySearchFilter");
            },
            generate_filter_text() {

                /*let filter_text = "Filter(s) Active --- ";*/
                this.filters_active_text = []


                if (this.filter_form.user_id != 0) {
                    let filter_text = "RPD ID : " + this.filter_form.user_id;
                    this.filters_active_text.push(filter_text)
                }

                if (this.filter_form.submitted_sdate != "" || this.filter_form.submitted_edate != "") {

                    let sdate = "mm/dd/yyyy"
                    let edate = "mm/dd/yyyy"

                    if (this.filter_form.submitted_sdate != "")
                        sdate = this.$moment(new Date(this.filter_form.submitted_sdate)).format("MM/DD/YYYY");
                    if (this.filter_form.submitted_edate != "")
                        edate = this.$moment(new Date(this.filter_form.submitted_edate)).format("MM/DD/YYYY");

                    let filter_text = "REGS : " + sdate + " - " + edate;
                    this.filters_active_text.push(filter_text)
                }

                if (this.filter_form.approved_sdate != "" || this.filter_form.approved_edate != "") {

                    let sdate = "mm/dd/yyyy"
                    let edate = "mm/dd/yyyy"

                    if (this.filter_form.approved_sdate != "")
                        sdate = this.$moment(new Date(this.filter_form.approved_sdate)).format("MM/DD/YYYY");
                    if (this.filter_form.approved_edate != "")
                        edate = this.$moment(new Date(this.filter_form.approved_edate)).format("MM/DD/YYYY");

                    let filter_text = "REGA : " + sdate + " - " + edate;
                    this.filters_active_text.push(filter_text)
                }

                if (this.filter_form.last_login_sdate != "" || this.filter_form.last_login_edate != "") {

                    let sdate = "mm/dd/yyyy"
                    let edate = "mm/dd/yyyy"

                    if (this.filter_form.last_login_sdate != "")
                        sdate = this.$moment(new Date(this.filter_form.last_login_sdate)).format("MM/DD/YYYY");
                    if (this.filter_form.last_login_edate != "")
                        edate = this.$moment(new Date(this.filter_form.last_login_edate)).format("MM/DD/YYYY");

                    let filter_text = "LLOGIN: " + sdate + " - " + edate;
                    this.filters_active_text.push(filter_text)
                }


                /* console.log(this.filters_active_text)*/
                this.$emit("filterTextUpdated", this.filters_active_text);
            },
            resetFilters() {


                this.$emit("resetFilter");
                this.generate_filter_text();
            },


        },
        computed: {

        },

        watch: {
            //Whenever object data changes emit new filter text
            filter_form: {
                handler(val) {
                    this.generate_filter_text();

                    if (this.$refs.filter_form)
                        this.$refs.filter_form.validate()
                },
                deep: true
            }
        }
    };
</script>
